@import url(https://fonts.googleapis.com/css2?family=Yomogi&display=swap);
/* Set the width of the sidebar to 120px */
.w3-sidebar {
  width: 120px;background: #222;
}

img.nav-bar-img {
  width: 100%;
  opacity: 0.5;
}

/* wave hands emoji */
.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}



.w3-image.my-image {
  margin-top: 25px;
  border-radius: 50%;
  transform: translatey(0px);
	animation: float 3s ease-in-out infinite;
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .main-header {
    font-size: 30px!important;
  }
}
#badges i {
  margin: 15px;
}

@media screen and (max-width: 600px) {
  #badges i {
    margin-left: 0;
  }
  .about-container {
    text-align: center;
  }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
    #badges i {
      margin: 0 8px 0 0;
    }
    #badges i:last-of-type {
      margin-left: 30px;
    }

}


.w3-row-padding img {
  margin-bottom: 12px;
}

img.project-image:hover {
  transition: all .3s ease-out;
  transform: scale(1.075);
}


body, h1,h2,h3,h4,h5,h6 {
  font-family: 'Yomogi', cursive;
}

body {
  font-size: 18px;
  color: #F0FFFF;
  /* overflow: hidden;
  margin: 0; */
}
 a {
   text-decoration: none;
 }

/* Add a left margin to the "page content" that matches the width of the sidebar (120px) */
#main {
  margin-left: 120px;
}



/*************** Media Queries *************/

@media only screen and (max-width: 600px) {
  /* Remove margins from "page content" on small screens */
  #main {
    margin-left: 0;
  }

  /* Reduce testimonials image size for smaller screens */
  /* img.w3-left.w3-circle.w3-margin-right {
    width: 60px;
  } */

  .project-image-container {
    height: auto;
  }

  h2, h3 {
    font-size: 22px!important;
  }
}

/* Add some padding to "main content" on screens smaller than 1100px */
@media only screen and (max-width: 1100px) {
  #main {
    padding: 10px;
  }

  /* Hide css typing section on small screens */
  .css-typing {
    display: none;
  }

  /* display small about section in larger screens */
  .about-section-small {
    display: block;
    font-weight: bold;
  }
}
   

