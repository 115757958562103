@import url('https://fonts.googleapis.com/css2?family=Yomogi&display=swap');

body, h1,h2,h3,h4,h5,h6 {
  font-family: 'Yomogi', cursive;
}

body {
  font-size: 18px;
  color: #F0FFFF;
  /* overflow: hidden;
  margin: 0; */
}
 a {
   text-decoration: none;
 }

/* Add a left margin to the "page content" that matches the width of the sidebar (120px) */
#main {
  margin-left: 120px;
}



/*************** Media Queries *************/

@media only screen and (max-width: 600px) {
  /* Remove margins from "page content" on small screens */
  #main {
    margin-left: 0;
  }

  /* Reduce testimonials image size for smaller screens */
  /* img.w3-left.w3-circle.w3-margin-right {
    width: 60px;
  } */

  .project-image-container {
    height: auto;
  }

  h2, h3 {
    font-size: 22px!important;
  }
}

/* Add some padding to "main content" on screens smaller than 1100px */
@media only screen and (max-width: 1100px) {
  #main {
    padding: 10px;
  }

  /* Hide css typing section on small screens */
  .css-typing {
    display: none;
  }

  /* display small about section in larger screens */
  .about-section-small {
    display: block;
    font-weight: bold;
  }
}
   
